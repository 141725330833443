import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom"
import {
    Anchor,
    Avatar,
    Box,
    Button,
    Grommet,
    Heading,
    Main,
    Text,
    Layer,
    Paragraph,
    grommet,
} from 'grommet';
import deepEqual from 'deep-equal';
import { questionComponents } from './question-components/index';
import { Helmet } from 'react-helmet';
import PageLoader from './PageLoader';
import styled from "styled-components";

import { PrivacyNotice } from '../PrivacyNotice.js';
import {
    subscriberFactory,
    unsubscriberFactory,
    rpcInvokerFactory,
    namer as eventNamer,
} from 'icc-lib';


const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const ControlBtn = styled(Button)`
    background-color: #d3cf1b;
            color: #464b4b;
            border: 2px solid #464b4b;  
            border-radius: 12px;
            text-transform: uppercase;
            &:hover{
                background-color: #d3cf1b;
                color: #464b4b;
                border: 2px solid #ffffff; 
                box-shadow:none;
            }
`;



const QuestionPre = (props) => (
    <>
        <Paragraph>Welcome to our community mapping survey.</Paragraph>
        <Paragraph>
            <em>
                Please note that you should only complete this survey on behalf of an
                organisation.
            </em>{' '}
            (By 'organisation' we also mean informal community groups, not just large
            constituted organisations)
        </Paragraph>
        <Paragraph>
            In this survey we will be asking you 14 short questions about your
            organisation, its goals and its connections within and outside of your
            community.
        </Paragraph>
        <Paragraph>
            This exercise is not about gathering perfect data. The answers that spring
            to mind as you answer tend to be the right ones. What we're looking at
            here is your general direction of travel, the patterns that form your
            community, and following on from that, being able to spot gaps and
            opportunities to build your resilience as a community.
        </Paragraph>
        <Paragraph>
            To continue on to the Mapping process you will need to read and agree to
            our Data policy.
        </Paragraph>
        <PrivacyNotice content={props.privacyContent} />
        <Heading level={2}>Your consent</Heading>
        <Paragraph>
            By continuing to the mapping process, you are agreeing to this privacy
            notice
        </Paragraph>
    </>
);

const QuestionPost = () => (
    <>
        <Paragraph>
            Thank you for taking the time to answer these questions.
        </Paragraph>
        <Paragraph>
            This is the community map that you have made:{' '}
            <Anchor color="link"
                href={`${window.location.href.split('?')[0]}/network`}
                label={`${window.location.href.split('?')[0]}/network`}
            />
        </Paragraph>
        <Paragraph>
            Please remember that as a contributor to the map you have privileged
            access to it and the personal data it contains (people's names). As such,
            please do not ever keep, copy or share this map with anyone else.
        </Paragraph>
    </>
);

const QuestionWait = () => (
    <>
        <Paragraph>
            Please wait for the next question to become available...
        </Paragraph>
    </>
);


export default function Forms(props) {
    const [projectConfig, setProjectConfig] = useState(null);
    const [datasetID, setDatasetID] = useState(null);
    const questionPreIndex = -1;
    const [questionPostIndex, setQuestionPostIndex] = useState(0);
    const [questionGateIndex, setQuestionGateIndex] = useState(null);
    const [questionIndex, setQuestionIndex] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (!props.socket || !props.user) {
            return;
        }

        if (props.socket.authState !== props.socket.AUTHENTICATED) {
            console.warn('Attempting project rpc when not authenticated');
        }

        const currentPath = window.location.pathname;
        const linkCode = getParameterByName('access');


        // console.log(window.location.search);
        let slug;
        try {
            slug = currentPath.split('/')[1];
        } catch {
            console.log('Could not parse location for project.', currentPath);
            return;
        }

        if (slug == '') {
            //props.setProjectSlug('403');
            console.log('error');
            navigate('/home');
        }
        else if (slug !== props.projectSlug) {
            console.log(props.projects, props.user);
            console.log(`Attempting to get project for '${slug}'`);
            //first check is the slug in there list of projects save self from doing next check 
            if (props.admin === true || props.projects.includes(slug)) {
                const rpc = rpcInvokerFactory(props.socket, 'project');
                rpc({
                    payload: { slug, linkCode },
                    handler: (result) => {
                        if (result.forceRefresh) {
                            window.location.reload();
                            return;
                        }
                        if (result.privacy) {
                            setPrivacyNotice(result.privacy);
                        }
                        // If we receive content, we are a member of that project
                        if (result.content) {
                            setQuestionGateIndex(result.questionNumber);
                            props.setProjectSlug(slug);
                            setDatasetID(result.datasetID);
                            setQuestionIndex(questionPreIndex);
                            setQuestionPostIndex(result.content.questions.length);
                            setProjectConfig(result.content);
                        }
                        // If not, we are not and should show the 403 message
                        else {
                            //props.setProjectSlug('403');
                            console.log('error');
                            navigate('/home');
                        }
                    },
                });
            } else {
                //props.setProjectSlug('403');
                console.log('error');
                navigate('/home');
            }
        }


    }, [props.socket, props.user, props.projectSlug, questionPreIndex, props.projects, navigate]);

    const [labelOrganisation, setLabelOrganisation] = useState(
        'Mapping Social Capital'
    );
    useEffect(() => {
        if (!props.socket || !datasetID) {
            return;
        }

        const subscribe = subscriberFactory(
            props.socket,
            eventNamer(datasetID, 'labelOrganisation')
        );
        subscribe({
            handler: (data) => setLabelOrganisation(data.data),
        });
        return unsubscriberFactory(
            props.socket,
            eventNamer(datasetID, 'labelOrganisation')
        );
    }, [props.socket, datasetID]);

    const [labelProject, setLabelProject] = useState('');
    useEffect(() => {
        if (!props.socket || !datasetID) {
            return;
        }

        const subscribe = subscriberFactory(
            props.socket,
            eventNamer(datasetID, 'labelProject')
        );
        subscribe({
            handler: (data) => setLabelProject(data.data),
        });
        return unsubscriberFactory(props.socket, eventNamer(datasetID, 'labelProject'));
    }, [props.socket, datasetID]);

    useEffect(() => {
        if (!props.socket || !datasetID) {
            return;
        }

        const subscribe = subscriberFactory(
            props.socket,
            eventNamer(datasetID, 'questionNumber')
        );
        subscribe({
            handler: (data) => {
                console.log('Question number updated', data);
                setQuestionGateIndex(data.questionNumber);
            },
        });
        return unsubscriberFactory(props.socket, eventNamer(datasetID, 'questionNumber'));
    }, [props.socket, datasetID]);

    const [question, setQuestion] = useState(null);
    useEffect(() => {
        if (!props.socket || !datasetID || questionIndex === undefined) {
            return;
        }

        if (
            questionIndex <= questionPreIndex ||
            questionIndex >= questionPostIndex
        ) {
            return;
        }

        // Issue: submit can be called before operation completes.
        // Quick fix: stop existing submit leaking across by clearing submit data state
        // Alternative would be some logic around 'questionHasCompletedLoad'
        setSubmitData();
        setData();
        setGroupedQuestionData({});
        setGroupedQuestionValid({});

        const name = eventNamer(datasetID, 'question', questionIndex);
        const subscribe = subscriberFactory(props.socket, name);
        subscribe({
            handler: (result) => {
                console.log(`Set question ${questionIndex}`, result);
                setSubmitData(result.data.submit);
                setQuestion({ ...result.data, id: questionIndex });
            },
        });
        return unsubscriberFactory(props.socket, name);
    }, [
        props.socket,
        datasetID,
        questionIndex,
        questionPreIndex,
        questionPostIndex,
        questionGateIndex,
    ]);

    const [submitData, setSubmitData] = useState();
    const [data, setData] = useState();
    const [groupedQuestionData, setGroupedQuestionData] = useState({});
    const [submitGroupedQuestionData, setSubmitGroupedQuestionData] = useState(
        {}
    );
    const [groupedQuestionValid, setGroupedQuestionValid] = useState({});
    const [privacyNotice, setPrivacyNotice] = useState('');
    const [valid, setValid] = useState(false);

    const hasGroupedQuestions = () =>
        question.groupedQuestions && question.groupedQuestions.length;

    const setMainData = (data) => {
        if (!hasGroupedQuestions) {
            setData(data);
        } else {
            setGroupedQuestionData((prevData) => ({ ...prevData, main: data }));
        }
    };

    const setMainValid = (isValid) => {
        console.log('MAIN VALID');
        if (!hasGroupedQuestions) {
            setValid(isValid);
        } else {
            setGroupedQuestionValid((prevData) => ({ ...prevData, main: isValid }));
            // setValid(isValid);
        }
    };

    const setGroupedData = (data, id) => {
        setGroupedQuestionData((prevData) => ({ ...prevData, [id]: data }));
    };

    const setGroupedValid = (isValid, id) => {
        // console.log('GROUPED VALID');
        setGroupedQuestionValid((prevData) => ({ ...prevData, [id]: isValid }));
    };

    useEffect(() => {
        setValid(Object.values(groupedQuestionValid).indexOf(false) === -1);
        console.log(groupedQuestionData);
        setData(groupedQuestionData['main']);
        // console.log('GROUPED QUESTION VALID CHANGED', groupedQuestionValid);
        // setMainData(data);
        // setMainValid(valid);
    }, [groupedQuestionData, groupedQuestionValid]);
    const submit = (onComplete) => {
        if (
            (data === undefined ||
                (submitData !== undefined && deepEqual(data, submitData))) &&
            (groupedQuestionData === undefined ||
                (groupedQuestionData !== undefined &&
                    deepEqual(groupedQuestionData, submitGroupedQuestionData)))
        ) {
            onComplete();
            return;
        }

        console.log('Submitting ', JSON.stringify(data));
        const rpc = rpcInvokerFactory(
            props.socket,
            eventNamer(datasetID, 'question', question.id)
        );
        rpc({
            payload: { submit: data, groupedSubmit: groupedQuestionData },
            handler: (result) => {
                console.log('Commit received ', JSON.stringify(result.data));
                onComplete();
            },
        });

        setSubmitData(data);
        setSubmitGroupedQuestionData(groupedQuestionData);
        setData();
    };

    const submitContentUpdate = useCallback(
        (updateData) => {
            console.log('Updating ', JSON.stringify(updateData));
            const rpc = rpcInvokerFactory(
                props.socket,
                eventNamer(datasetID, 'question', question.id)
            );
            rpc({
                payload: { update: updateData },
                handler: (result) =>
                    console.log('Update received ', JSON.stringify(result.data)),
            });
        },
        [props.socket, datasetID, question]
    );

    const QuestionComponent = question && questionComponents[question.kind];

    console.log(labelProject, labelOrganisation);

    return (
        <>
            <Helmet>
                <title>
                    {labelOrganisation}-{labelProject}
                </title>
            </Helmet>

            {questionIndex === undefined ?
                (<PageLoader />) : (
                    <>
                        <Heading level='1'>{labelProject}</Heading>

                        {questionIndex <= questionPreIndex && (
                            <QuestionPre privacyContent={privacyNotice} />
                        )}

                        {question &&
                            (questionGateIndex === null ||
                                questionIndex <= questionGateIndex) &&
                            questionIndex > questionPreIndex &&
                            questionIndex < questionPostIndex && (
                                <>
                                    <QuestionComponent
                                        {...question}
                                        projectConfig={projectConfig}
                                        onData={setMainData}
                                        onContentUpdate={submitContentUpdate}
                                        onValid={setMainValid}
                                    />

                                    {question.groupedQuestions &&
                                        question.groupedQuestions.map((x, idx) => {
                                            const GroupedComponent =
                                                x && questionComponents[x.data.kind];

                                            return (
                                                <GroupedComponent
                                                    {...x.data}
                                                    key={idx}
                                                    id={`grouped--${x.questionNumber}`}
                                                    onData={setGroupedData}
                                                    onValid={setGroupedValid}
                                                />
                                            );
                                        })}
                                </>
                            )}

                        {questionGateIndex !== null &&
                            questionIndex < questionPostIndex &&
                            questionIndex > questionPreIndex &&
                            questionIndex > questionGateIndex && <QuestionWait />}

                        {questionIndex >= questionPostIndex && <QuestionPost />}

                        <Box
                            flex={false}
                            direction='row'
                            gap='medium'
                            pad={{ top: 'small', bottom: 'large' }}
                        >
                            {questionIndex > questionPreIndex && (
                  /*questionGateIndex === null ||
                  questionIndex <= questionGateIndex && */ <ControlBtn
                                    label='Previous'
                                    onClick={() => {
                                        const onComplete = () => {
                                            setQuestionIndex(questionIndex - 1);
                                        };
                                        submit(onComplete);
                                    }}
                                />
                            )}
                            {questionIndex < questionPostIndex &&
                                (questionGateIndex === null ||
                                    questionIndex <= questionGateIndex) && (
                                    <ControlBtn
                                        primary
                                        label='Next'
                                        disabled={!(questionIndex <= questionPreIndex || valid)}
                                        onClick={(e) => {
                                            const onComplete = () => {
                                                const extraJump =
                                                    question && question.groupedQuestions
                                                        ? question.groupedQuestions.length
                                                        : 0;
                                                setQuestionIndex(questionIndex + 1 + extraJump);
                                            };
                                            submit(onComplete);
                                        }}
                                    />
                                )}
                        </Box>
                    </>
                )}

        </>
    );
}
