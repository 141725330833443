import React from 'react';
import { Heading, Paragraph } from 'grommet';

export const PrivacyNotice = (props) => (
  <>
    <div
      className='privacy-wrapper'
      dangerouslySetInnerHTML={{ __html: props.content }}
    ></div>

    {/* <Heading level={2}>Data Policy 2021</Heading>
    <Paragraph>
      This Privacy Notice has been drawn up for Understory, a project which aims
      to map Social Capital in geographical communities. Social Capital is
      defined by the OECD as: ‘networks together with shared norms, values and
      understandings that facilitate co-operation within or among groups.’
    </Paragraph>
    <Heading level={3}>Who is collecting the data?</Heading>
    <Paragraph>
      Free Ice Cream and Onion Collective partner with the community’s anchor
      organisation as Joint Data Controllers. These organisations facilitate the
      community to collect data about itself which is then held securely by Free
      Ice Cream and any third party data processing software necessary to
      deliver the service
    </Paragraph>
    <Heading level={3}>What data is being collected?</Heading>
    <Paragraph>
      <Paragraph>
        Data is being collected about who is in a community: organisational
        names and characteristics, individual names, the goals people are
        working towards, and who is connected to who.
      </Paragraph>
      <Heading level={4}>We collect three categories of personal data:</Heading>
      <Paragraph>
        The email addresses of everyone who contributes to the mapping process.
        These are collected in order to authorise users to access their
        community map and to restrict access to anyone not in the community.
        These emails are collected and then held separately on authorisation
        software servers.
      </Paragraph>
      <Paragraph>
        The names of everyone who contributes to the mapping process. By
        agreeing to this data policy you give your permission for us to publish
        your name on the map we are making. Your name will only be visible to
        people authorised by the community anchor organisation and not to the
        general public.
      </Paragraph>
      <Paragraph>
        The names of people who are named in the mapping process. This data is
        only visible to those people authorised by the community anchor
        organisation and not to the general public. If the named individuals are
        not made aware of this project and this Data Policy then their name will
        be deleted at 30 days. If they are made aware of the project and the
        policy then their name can be kept for up to 90 days. If they give their
        consent, their name can remain in the map.
      </Paragraph>
    </Paragraph>
    <Heading level={3}>
      What is the legal basis for processing the data?
    </Heading>
    <Paragraph>
      Section 6.1(f) of the GDPR allows for processing data under Legitimate
      Interest (i.e: it is in the public benefit to collect this data)
    </Paragraph>
    <Heading level={3}>Will the data be shared anywhere?</Heading>
    <Paragraph>
      The map that is created will be shared with the contributors. After 30
      days some personal data may be erased in accordance with the guidance
      above and the community will have continued access to this map secured
      behind user accounts. A version of the map with all individuals’ names
      taken out will be published online and made accessible to the public.
    </Paragraph>
    <Heading level={3}>How will the information be used?</Heading>
    <Paragraph>
      The community will be able to use the map to explore the ways in which
      they are connected and build community resilience. Free Ice Cream and the
      Onion Collective will have access to all published community maps in order
      to build a broader understanding of community resilience and social
      capital andmay share this intelligence with other organisations. This
      broader analysis work will never include personal data.
    </Paragraph>
    <Heading level={3}>How long will the data be stored for?</Heading>
    <Paragraph>
      Free Ice Cream will store the data securely on their own and on
      authorisation software servers until the community wants it deleted.
    </Paragraph>
    <Heading level={3}>What rights do I have?</Heading>
    <Paragraph>
      You have rights over any and all information stored on the system related
      to yourself (unless it would infringe on another person’s privacy to
      access it) and therefore you may:
      <ul>
        <li>view it (or ask for a copy),</li>
        <li>ask for it to be amended (if incorrect or irrelevant),</li>
        <li>request for it to be deleted.</li>
      </ul>
      To exercise the above rights, please contact us at
      data@understory.community
    </Paragraph>
    <Heading level={3}>
      The community has a part to play, and a responsibility:
    </Heading>
    <Paragraph>
      <ol>
        <li>
          As part of the mapping, you will name people and organisations that
          are not in the room with you. We ask that you tell anyone you named in
          the map about this mapping process and send them this Data Policy,
          which we will provide to you. If they are part of an organisation,
          please invite them to get in touch with the project leaders to join
          the mappingprocess and make the map as representative of the community
          as possible.
        </li>
        <li>
          The information you receive or have access to through use of our
          services should only be used for its specified purposes, for no longer
          than needed and you must not keep, make a copy or share it.
        </li>
      </ol>
    </Paragraph>
    <Paragraph>
      As we develop these mapping tools and their use in communities, our data
      policy is likely to change and will be updated accordingly. From time to
      time you will need to read these changes to keep using the service. You
      can always email us at data@understory.community if you would like your
      data to be amended or deleted.
      <Heading level={3}>(Version 5, 2nd March 2021)</Heading>
    </Paragraph> */}
  </>
);
